@keyframes ldio-h6vfruwm00t {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-h6vfruwm00t div {
  left: 51.7px;
  top: 19.25px;
  position: absolute;
  animation: ldio-h6vfruwm00t linear 1.0204081632653061s infinite;
  background: #1dc5df;
  width: 6.6000000000000005px;
  height: 14.3px;
  border-radius: 3.3000000000000003px / 7.15px;
  transform-origin: 3.3000000000000003px 35.75px;
}
.ldio-h6vfruwm00t div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9523809523809524s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(2) {
  transform: rotate(24deg);
  animation-delay: -0.8843537414965987s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(3) {
  transform: rotate(48deg);
  animation-delay: -0.8163265306122449s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(4) {
  transform: rotate(72deg);
  animation-delay: -0.7482993197278912s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(5) {
  transform: rotate(96deg);
  animation-delay: -0.6802721088435374s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(6) {
  transform: rotate(120deg);
  animation-delay: -0.6122448979591837s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(7) {
  transform: rotate(144deg);
  animation-delay: -0.54421768707483s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(8) {
  transform: rotate(168deg);
  animation-delay: -0.4761904761904762s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(9) {
  transform: rotate(192deg);
  animation-delay: -0.40816326530612246s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(10) {
  transform: rotate(216deg);
  animation-delay: -0.3401360544217687s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(11) {
  transform: rotate(240deg);
  animation-delay: -0.272108843537415s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(12) {
  transform: rotate(264deg);
  animation-delay: -0.20408163265306123s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(13) {
  transform: rotate(288deg);
  animation-delay: -0.1360544217687075s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(14) {
  transform: rotate(312deg);
  animation-delay: -0.06802721088435375s;
  background: #1dc5df;
}
.ldio-h6vfruwm00t div:nth-child(15) {
  transform: rotate(336deg);
  animation-delay: 0s;
  background: #1dc5df;
}
.loadingio-spinner-spinner-5pvok04ktu3 {
  width: 110px;
  height: 110px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-h6vfruwm00t {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-h6vfruwm00t div {
  box-sizing: content-box;
}

.loadingio-spinner-spinner-5pvok04ktu3 {
  position: fixed;
  z-index: 200;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
