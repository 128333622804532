.data_filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
}

.filter-item {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 300px;
}

.filter-item label {
  font-size: 16px;
  margin-right: 8px;
  color: #333;
}

.filter-item select,
.filter-item input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px; /* تحسين حجم النص */
  width: 100%;
}

.apply-filter-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.apply-filter-btn:hover {
  background-color: #45a049;
}

.rowDivBtns {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 0 30px;
}

/* ============================== */

/* Add this to your style.css or a similar CSS file */



.popup-abc123 {
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Arial', sans-serif;
}

.popup-abc123 h2 {
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.popup-abc123 label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555555;
  font-size: 14px;
}

.popup-abc123 input[type="text"],
.popup-abc123 input[type="checkbox"],
.popup-abc123 select,
.popup-abc123 textarea {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  transition: border-color 0.3s ease;
  margin-bottom: 12px;
  font-size: 16px;
  box-sizing: border-box;
}

.popup-abc123 input[type="text"]:focus,
.popup-abc123 input[type="checkbox"]:focus,
.popup-abc123 select:focus,
.popup-abc123 textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.popup-abc123 .form-group-xyz321 {
  margin-bottom: 24px;
}

.popup-abc123 .choices-container-pqr789 {
  margin-top: 16px;
}

.popup-abc123 .button-container-stu456 {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.popup-abc123 .button-container-stu456 button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-abc123 .button-container-stu456 button:hover {
  background-color: #0056b3;
}

.popup-abc123 .add-remove-buttons-uvw234 {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
}

.popup-abc123 .add-remove-buttons-uvw234 button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #28a745;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-abc123 .add-remove-buttons-uvw234 button:hover {
  background-color: #218838;
}

.popup-abc123 .choice-fields-xyz567 {
  margin-top: 16px;
}

.popup-abc123 .choice-fields-xyz567 .choice-field-rst890 {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.popup-abc123 .choice-fields-xyz567 .choice-field-rst890 input {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  font-size: 16px;
  box-sizing: border-box;
}

.popup-abc123 .choice-fields-xyz567 .choice-field-rst890 button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #dc3545;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-abc123 .choice-fields-xyz567 .choice-field-rst890 button:hover {
  background-color: #c82333;
}

.choice-field-rst890,
.choices-container-pqr789 {
  margin-bottom: 10px;
}

.popup-abc123 .choices-container-pqr789 > button {
  margin: 0 10px !important;
}

/* تحسينات إضافية */
.popup-abc123 .input-wrapper {
  position: relative;
}

.popup-abc123 .input-wrapper input {
  padding-right: 40px;
}

.popup-abc123 .input-wrapper .icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
}

/* تحسين شكل الحقول */
.popup-abc123 .choice-fields-xyz567 .choice-field-rst890 input,
.popup-abc123 .choices-container-pqr789 input {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
}

.popup-abc123 .choice-fields-xyz567 .choice-field-rst890 input:focus,
.popup-abc123 .choices-container-pqr789 input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* تصميم الكروت */
.popup-abc123 .card {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup-abc123 .card label {
  font-size: 16px;
  color: #495057;
}

.popup-abc123 .card .choices-container-pqr789 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.popup-abc123 .card .choices-container-pqr789 input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  font-size: 14px;
}

.popup-abc123 .card .add-remove-buttons-uvw234 {
  margin-top: 8px;
  justify-content: flex-start;
}

.popup-abc123 .card .add-remove-buttons-uvw234 button {
  background-color: #6c757d;
}

.popup-abc123 .card .add-remove-buttons-uvw234 button:hover {
  background-color: #5a6268;
}

/* تحسين ترتيب الأزرار */
.popup-abc123 .button-container-stu456 button:first-of-type {
  background-color: #28a745;
}

.popup-abc123 .button-container-stu456 button:first-of-type:hover {
  background-color: #218838;
}


.popup-abc123 .multiple-choice-card {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup-abc123 .multiple-choice-card label {
  font-size: 16px;
  color: #495057;
}

.popup-abc123 .multiple-choice-card .fields-container {
  margin-top: 12px;
}

.popup-abc123 .multiple-choice-card .fields-container .field {
  margin-bottom: 12px;
}

.popup-abc123 .multiple-choice-card .fields-container .field label {
  font-size: 14px;
  color: #6c757d;
}

.popup-abc123 .multiple-choice-card .fields-container .field input {
  width: calc(100% - 40px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  font-size: 14px;
}

.popup-abc123 .multiple-choice-card .fields-container .field .choices-container {
  margin-top: 8px;
}

.popup-abc123 .multiple-choice-card .fields-container .field .choices-container .choice {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.popup-abc123 .multiple-choice-card .fields-container .field .choices-container .choice label {
  font-size: 14px;
  color: #6c757d;
}

.popup-abc123 .multiple-choice-card .fields-container .field .choices-container .choice input {
  width: calc(100% - 40px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ced4da;
  font-size: 14px;
}

.popup-abc123 .multiple-choice-card .fields-container .field .choices-container .choice .btn-remove {
  background-color: #dc3545;
  color: #ffffff;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-abc123 .multiple-choice-card .fields-container .field .choices-container .choice .btn-remove:hover {
  background-color: #c82333;
}
