.btn {
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0069d9;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
  color: #fff;
}

.btn-success {
  background-color: #28a745;
  color: #fff;
  border: none;
}

.btn-success:hover {
  background-color: #218838;
  color: #fff;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
  border: none;
}

.btn-danger:hover {
  background-color: #c82333;
  color: #fff;
}

.btn-warning {
  background-color: #ffc107;
  color: #000;
  border: none;
}

.btn-warning:hover {
  background-color: #e0a800;
  color: #000;
}

.btn-info {
  background-color: #17a2b8;
  color: #fff;
  border: none;
}

.btn-info:hover {
  background-color: #138496;
  color: #fff;
}

.btn-light {
  background-color: #f8f9fa;
  color: #000;
  border: none;
}

.btn-light:hover {
  background-color: #e2e6ea;
  color: #000;
}

.btn-dark {
  background-color: #343a40;
  color: #fff;
  border: none;
}

.btn-dark:hover {
  background-color: #23272b;
  color: #fff;
}
