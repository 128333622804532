.file-card {
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;

}
 .file-card .file-inputs {
	 position: relative;
}
 .file-card .file-inputs input {
	 position: relative;
	 text-align: right;
	 opacity: 0;
	 z-index: 2;
	 cursor: pointer;
	 height: 30px;
	 max-width: 130px;
}
 .file-card .file-inputs button {
	 position: absolute;
	 top: 0px;
	 left: 0px;
	 width: 100%;
	 height: 100%;
	 z-index: 1;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 color: #0388CC;
	 background-color: #fff;
	 font-size: 14px;
	 font-weight:400;
	 cursor: pointer;
	 border-radius: 4px;
	 border: 1px solid #A5B4CB;
}
 .file-card .file-inputs button i {
	 width: 1.5em;
	 height: 1.5em;
	 padding: 0.4em;
	 color: #0388CC;
	 border-radius: 50%;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 margin-right: 0.8em;
	 font-size: 14px;
	 font-weight:400;
}
 .file-card .file-inputs:hover button {
	 background-color: #0388CC;
	 color:#fff
}
 .file-card .file-inputs:hover button i {
	 color:#fff
}
 .main {
	 font-weight: bold;
	 margin-bottom: 0.4em;
}
 .info {
	 font-size: 0.8rem;
}
 